import { BASE_ASSETS_URL } from "../utils/constants";

export function getCardSchemesDetails(cardSchemes: string[]) {
  return cardSchemes.map((scheme) => ({
    displayName: scheme,
    label: scheme,
    logoUrl: `${BASE_ASSETS_URL}card-brands/${scheme.toLowerCase()}.svg`,
    name: scheme
  }));
}
