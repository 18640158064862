import { PaymentChannelsPropertiesType } from "@xendit/checkout-utilities/dist/src/types/payment-channels";
import { PaymentMethodsEnum } from "@xendit/checkout-utilities/dist/src/enums/payment-methods";
import { PaymentChannelsEnum } from "@xendit/checkout-utilities/dist/src/enums/payment-channels";
import { CountriesEnum } from "@xendit/checkout-utilities";
import set from "lodash/set";
import get from "lodash/get";

import { getPaymentMethodPropertyKey } from ".";
import {
  getProcessingPageUrl,
  stripTrailingSlashFromPathname
} from "../../utils/urls";
import { mapPAPIChannelCode } from "../../helpers/channel-mapping";
import { SupportedPaymentRequestReturnStatusHash } from "../../helpers/payment-request";

type PaymentRequestPayloadType = {
  payment_method: object;
  customer?: object;
  force_request?: boolean;
};

export const getPaymentRequestPayload = (
  invoiceId: string,
  paymentChannel: PaymentChannelsPropertiesType,
  opts?: {
    userArgs?: {
      payment_method_channel_properties?: object;
      customer?: {
        given_names?: string;
        surname?: string;
        email?: string;
        mobile_number?: string;
      };
    };
  },
  customer?: object | null
) => {
  const paymentMethodPropertyKey = getPaymentMethodPropertyKey(
    paymentChannel.payment_method
  );

  // Set and clear query params
  const cancelReturnUrl = new URL(window.location.href);
  cancelReturnUrl.pathname = stripTrailingSlashFromPathname(
    cancelReturnUrl.pathname
  );
  cancelReturnUrl.search = "";
  cancelReturnUrl.hash = SupportedPaymentRequestReturnStatusHash.CANCELLED;

  // Set and clear query params
  const failureReturnUrl = new URL(window.location.href);
  failureReturnUrl.pathname = stripTrailingSlashFromPathname(
    cancelReturnUrl.pathname
  );
  failureReturnUrl.search = "";
  failureReturnUrl.hash = SupportedPaymentRequestReturnStatusHash.FAILED;

  // Set and clear query params
  const pendingReturnUrl = new URL(window.location.href);
  pendingReturnUrl.pathname = stripTrailingSlashFromPathname(
    cancelReturnUrl.pathname
  );
  pendingReturnUrl.search = "";

  // NOTE: This is a short term fix to handle failure for direct debit payment.
  if (paymentChannel.payment_method === PaymentMethodsEnum.DIRECT_DEBIT) {
    failureReturnUrl.searchParams.append("error_code", "Payment failed");
    failureReturnUrl.searchParams.append(
      "error_message",
      "Please try again or use another payment method."
    );
  }

  const paymentMethod = {
    type: paymentChannel.payment_method,
    reusability: "ONE_TIME_USE",
    [paymentMethodPropertyKey as string]: {
      channel_properties: {
        success_return_url: getProcessingPageUrl().toString(),
        failure_return_url: failureReturnUrl.toString(),
        cancel_return_url: cancelReturnUrl.toString(),
        pending_return_url: pendingReturnUrl.toString(),
        ...opts?.userArgs?.payment_method_channel_properties
      }
    }
  };

  if (!["QR_CODE", "CRYPTOCURRENCY"].includes(paymentChannel.payment_method)) {
    set(
      paymentMethod,
      `${paymentMethodPropertyKey}.channel_code`,
      mapPAPIChannelCode(paymentChannel.channel)
    );
  }

  let customerDetails = customer;

  if (paymentChannel.payment_method === PaymentMethodsEnum.DIRECT_DEBIT) {
    customerDetails = {
      given_names: get(opts, "userArgs.customer.given_names", invoiceId),
      surname: get(opts, "userArgs.customer.surname", invoiceId),
      email: get(opts, "userArgs.customer.email", `${invoiceId}@yopmail.com`),
      mobile_number: get(
        opts,
        "userArgs.customer.mobile_number",
        "+639061234567"
      )
    };
  }
  if (
    paymentChannel.payment_method === ("CRYPTOCURRENCY" as PaymentMethodsEnum)
  ) {
    customerDetails = {
      given_names: get(opts, "userArgs.customer.firstName"),
      surname: get(opts, "userArgs.customer.lastName"),
      email: get(opts, "userArgs.customer.email"),
      mobile_number: get(opts, "userArgs.customer.mobileNumber", "").replace(
        /\s/g,
        ""
      ),
      addresses: [
        {
          street_line1: get(opts, "userArgs.customer.address"),
          city: get(opts, "userArgs.customer.city"),
          postal_code: get(opts, "userArgs.customer.postalCode"),
          country: get(opts, "userArgs.customer.country")
        }
      ]
    };
  }

  const paymentRequestPayload = {
    payment_method: paymentMethod
  } as PaymentRequestPayloadType;

  if (customerDetails) {
    paymentRequestPayload["customer"] = customerDetails;
  }

  // Force request for MY_WECHATPAY is needed because users may sometimes change between
  // desktop and mobile when trying to complete a single payment. This ensures that the
  // correct payment flow is executed.
  const isChannelCodeMYWeChatPay =
    paymentChannel.channel === PaymentChannelsEnum.WECHATPAY &&
    paymentChannel.country_of_operation ===
      CountriesEnum.Malaysia.toUpperCase();

  if (
    paymentChannel.channel === PaymentChannelsEnum.DD_BRI ||
    isChannelCodeMYWeChatPay
  ) {
    paymentRequestPayload["force_request"] = true;
  }

  return paymentRequestPayload;
};
