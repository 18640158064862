import { FC, SyntheticEvent, useCallback, useRef } from "react";
import { formatDisplayAmountWithCurrencyCode } from "@xendit/checkout-utilities/dist/src/amount-formatter";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { usePaymentLink } from "../../contexts/PaymentLinkContext";
import { usePaymentMethod } from "../../contexts/PaymentMethodContext";
import { usePaymentRequest } from "../../contexts/PaymentRequestContext";

import useQrCode from "../../hooks/use-qr-code.hook";

import { AlertCircle, ArrowLeft, Warning } from "../../assets/icons";
import { isMobileDevice } from "../../helpers/is-mobile-device";
import Button from "../../components/Button";
import PaymentView from "../../components/PaymentView";
import QrCode from "../../components/QrCode";
import RollingLoader from "../../components/RollingLoader";

const PaymentQrCode: FC = () => {
  const {
    paymentLink: {
      invoice: { id, amount, currency }
    }
  } = usePaymentLink();
  const { clearPaymentChannel, paymentChannel } = usePaymentMethod();
  const { error, initializing, paymentRequest } = usePaymentRequest();
  const { t } = useTranslation("common");
  const isMobile = isMobileDevice();

  const qrRef = useRef<HTMLCanvasElement>(null);
  const { downloadQRCode } = useQrCode(qrRef, {
    downloadFilename: `checkout-${id}-qr-code.jpg`
  });

  const presentToCustomerAction = paymentRequest?.actions?.find(
    (action) => action.action === "PRESENT_TO_CUSTOMER"
  ) as PaymentRequestPresentToCustomerAction | undefined;
  const authAction = paymentRequest?.actions?.find(
    (action) => action.action === "AUTH"
  ) as PaymentRequestAuthAction | undefined;

  const qrCodeString = presentToCustomerAction?.qr_code;
  const shouldDisplayJumpAppButton =
    !!paymentRequest && authAction?.url_type === "DEEPLINK" && !qrCodeString;

  const handleDownloadClick = useCallback(
    (e: SyntheticEvent) => {
      if (qrCodeString) {
        downloadQRCode(e);
      }
    },
    [downloadQRCode, qrCodeString]
  );

  const handleQrCodeClick = useCallback(() => {
    if (authAction) {
      window.location.href = authAction.url;
    } else if (presentToCustomerAction) {
      window.location.href = presentToCustomerAction.qr_code;
    }
  }, [authAction, presentToCustomerAction]);

  const hasError =
    !!error || (!initializing && !qrCodeString && !shouldDisplayJumpAppButton);

  if (
    !paymentChannel ||
    (paymentChannel.properties as { type: string }).type !== "QR_CODE"
  ) {
    return null;
  }

  const actionButtonsContainerClassName = classNames([
    "flex-col space-y-2",
    {
      "hidden md:flex": !shouldDisplayJumpAppButton,
      flex: shouldDisplayJumpAppButton
    }
  ]);

  return (
    <PaymentView
      header
      centeredHeader
      paymentChannelName={paymentChannel.display_name}
      paymentChannelLogoUrl={paymentChannel.logo_url}
      amount={formatDisplayAmountWithCurrencyCode(amount, currency)}
      footer={
        !shouldDisplayJumpAppButton ? (
          <div className="flex space-x-4">
            <Button
              type="button"
              onClick={clearPaymentChannel}
              variant="brand-secondary"
              outline
              className="flex-shrink-0"
            >
              <ArrowLeft />
            </Button>
            <Button
              type="submit"
              variant="brand-secondary"
              className="flex-1"
              onClick={handleDownloadClick}
              disabled={!qrCodeString}
            >
              {t("Download QR Code")}
            </Button>
          </div>
        ) : null
      }
    >
      <div className="flex flex-col items-center space-y-8 py-8">
        {shouldDisplayJumpAppButton ? (
          <div
            className="flex items-center text-sm px-4 py-3 bg-xen-yellow-100 text-xen-yellow-800"
            role="alert"
            data-testid="info-box-container"
          >
            <div className="mr-4" data-testid="icon-container">
              <Warning className="h-6 w-6" />
            </div>
            <div data-testid="content-container">
              <p className="font-semibold">
                {t(
                  "Please note that this payment can only be processed by using {{channel}}.",
                  {
                    channel: paymentChannel.display_name
                  }
                )}
              </p>
            </div>
          </div>
        ) : null}

        {initializing ? (
          <RollingLoader className="text-primary w-24 h-24" />
        ) : null}

        {hasError ? (
          <div className="flex items-center">
            <AlertCircle className="text-xen-yellow-500 h-6 w-6 mr-1" />
            <p className="text-center">{t("QR code is not available.")}</p>
          </div>
        ) : null}

        {qrCodeString ? (
          <div
            className="flex flex-col w-80"
            data-testid={`${paymentChannel.label.toLowerCase()}-qr-code`}
          >
            <div className="bg-black text-white py-3 font-semibold uppercase text-center px-4 rounded-t-xl">
              {t(isMobile ? "Scan or tap QR to pay" : "Scan QR to pay")}
            </div>
            <div className="w-full h-80 border border-t-0 border-xen-gray-400 rounded-b-xl flex justify-center items-center p-1.5">
              <button onClick={handleQrCodeClick}>
                <QrCode value={qrCodeString} ref={qrRef} />
              </button>
            </div>
          </div>
        ) : null}

        {!!paymentRequest && isMobile ? (
          <p className="text-sm text-center">
            {t("Please allow popup to be redirected to {{channel}} app.", {
              channel: paymentChannel.display_name
            })}
          </p>
        ) : null}
        <div className={actionButtonsContainerClassName}>
          {qrCodeString ? (
            <Button
              type="button"
              onClick={handleDownloadClick}
              variant="brand-secondary"
              className="w-64"
              disabled={!qrCodeString}
              data-testid="download-qr-code"
            >
              {t("Download QR Code")}
            </Button>
          ) : null}
          {shouldDisplayJumpAppButton ? (
            <Button
              type="button"
              onClick={handleQrCodeClick}
              variant="brand-secondary"
              className="w-64"
              data-testid="jump-app"
            >
              {t("Open in app", {
                channel: paymentChannel.display_name
              })}
            </Button>
          ) : null}
          <Button
            type="button"
            onClick={clearPaymentChannel}
            variant="brand-secondary"
            outline
            className="w-64"
            data-testid="back"
          >
            {t("Back")}
          </Button>
        </div>
      </div>
    </PaymentView>
  );
};

export default PaymentQrCode;
