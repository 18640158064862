import { FC } from "react";
import { useTranslation } from "react-i18next";
import { withMask } from "use-mask-input";
import { useSessionContext } from "../../../../../context/context";
import { getCountryCodeFromBusiness } from "../../../../../lib/country-code-map";
import { PaymentMethod } from "../../../../../types";
import ChannelPropertiesForm from "../../ChannelPropertiesForm/ChannelPropertiesForm";
import { ExtraChannelPropertyInput } from "../../ChannelPropertiesForm/GenerateChannelPropertiesInputs";
import { THDirectDebitFormAValues, useTHDirectDebitFormikA } from "./formik";

const THDirectDebitFormA: FC<{
  paymentMethod?: PaymentMethod;
  onSubmit: (values: THDirectDebitFormAValues) => Promise<void>;
  onCancel: () => void;
}> = (props) => {
  const { paymentMethod, onSubmit, onCancel } = props;

  // Context
  const { state } = useSessionContext();
  const { business, session } = state;

  if (!business || !session || !paymentMethod) {
    throw new Error("There was a problem loading the state", {
      cause: { state }
    });
  }

  // Translation
  const { t } = useTranslation("session");

  // Channel properties input
  // Use a union of string literals of the channel properties
  type ChannelProperties = "account_mobile_number" | "identity_document_number";
  const mobileCountryCode = getCountryCodeFromBusiness(business);
  const channelPropertyInputs: ExtraChannelPropertyInput<ChannelProperties>[] =
    [
      {
        name: "account_mobile_number",
        label: t("Mobile Number"),
        placeholder: `${mobileCountryCode} 2 2134567`,
        ref: withMask(`${mobileCountryCode} 9 9999999`, {
          placeholder: "",
          onBeforePaste: (value: string) =>
            !value.startsWith("+") ? `+${value}` : value
        }),
        width: "full",
        inputType: "tel",
        required: true
      },
      {
        name: "identity_document_number",
        label: t("Identity Document Number"),
        width: "full",
        inputType: "text",
        required: true
      }
    ];

  const formikValues = useTHDirectDebitFormikA({
    onSubmit
  });

  return (
    <ChannelPropertiesForm
      {...formikValues}
      paymentMethod={paymentMethod}
      channelPropertyInputs={channelPropertyInputs}
      onCancel={onCancel}
    ></ChannelPropertiesForm>
  );
};

export default THDirectDebitFormA;
