import { FC } from "react";
import { useTranslation } from "react-i18next";
import { withMask } from "use-mask-input";
import { useSessionContext } from "../../../../../context/context";
import { getCountryCodeFromBusiness } from "../../../../../lib/country-code-map";
import { PaymentMethod } from "../../../../../types";
import ChannelPropertiesForm from "../../ChannelPropertiesForm/ChannelPropertiesForm";
import { ExtraChannelPropertyInput } from "../../ChannelPropertiesForm/GenerateChannelPropertiesInputs";
import { DirectDebitFormValues, useDDFormik } from "./formik";

/**
 * Direct debit Form that accepts OTP. Used by BRI and BCA
 * Needs to be wrapped in React.Suspense in order to load the translations asynchronously.
 * Otherwise, an error will be thrown
 */
const BRIandBCAForm: FC<{
  paymentMethod?: PaymentMethod;
  onSubmit: (values: DirectDebitFormValues) => Promise<void>;
  onCancel: () => void;
}> = (props) => {
  const { paymentMethod, onSubmit, onCancel } = props;
  const { state } = useSessionContext();
  const { t } = useTranslation("session");

  // Context
  const { business } = state;

  if (!paymentMethod || !business) {
    throw new Error("There was a problem loading the state", {
      cause: { state }
    });
  }

  const mobileCountryCode = getCountryCodeFromBusiness(business);

  type ChannelProperties =
    | "account_mobile_number"
    | "card_last_four"
    | "card_expiry"
    | "account_email";
  const channelPropertyInputs: ExtraChannelPropertyInput<ChannelProperties>[] =
    [
      {
        name: "account_mobile_number",
        label: t("Mobile Number"),
        placeholder: `${mobileCountryCode} 812 345 6789`,
        ref: withMask(`${mobileCountryCode} 999 999 99999`, {
          placeholder: "",
          onBeforePaste: (value: string) =>
            !value.startsWith("+") ? `+${value}` : value
        }),
        width: "1/2",
        inputType: "tel",
        required: true
      },
      {
        name: "account_email",
        label: t("Email Address"),
        placeholder: "abc@gmail.com",
        width: "1/2",
        inputType: "email",
        required: true
      },
      {
        name: "card_last_four",
        label: t("Card Number Last 4 Digits"),
        placeholder: "1234",
        ref: withMask("9999", {
          placeholder: "",
          showMaskOnHover: false,
          autoUnmask: true
        }),
        width: "1/2",
        inputType: "text",
        required: true
      },
      {
        name: "card_expiry",
        label: t("Valid Thru"),
        placeholder: "MM/YY",
        ref: withMask("99/99", {
          placeholder: "",
          showMaskOnHover: false
        }),
        width: "1/2",
        inputType: "text",
        required: true
      }
    ];

  const formikValues = useDDFormik({
    onSubmit
  });

  // Render
  return (
    <ChannelPropertiesForm
      {...formikValues}
      paymentMethod={paymentMethod}
      channelPropertyInputs={channelPropertyInputs}
      onCancel={onCancel}
    />
  );
};

export default BRIandBCAForm;
