export const getProcessingPageUrl = () => {
  // Set and clear query params
  const url = new URL(window.location.href);
  url.search = "";
  url.pathname = stripTrailingSlashFromPathname(url.pathname) + "/processing";
  return url;
};

export const stripTrailingSlashFromPathname = (pathname: string) => {
  if (pathname.endsWith("/")) {
    return pathname.slice(0, -1);
  }
  return pathname;
};
