import * as yup from "yup";
import { FormikErrors, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import validateCardExpirationDate from "../../../../../../helpers/validate-card-expiry";

export type DirectDebitFormValues = {
  account_mobile_number: string;
  account_email: string;
  card_last_four: string;
  card_expiry: string;
};

export const useDDFormik = (props: {
  onSubmit: (values: DirectDebitFormValues) => Promise<void>;
}) => {
  const { onSubmit } = props;
  const { t } = useTranslation("session");
  const {
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
    values,
    touched,
    isValid,
    dirty
  } = useFormik<DirectDebitFormValues>({
    initialValues: (() => {
      return {
        account_mobile_number: "",
        account_email: "",
        card_last_four: "",
        card_expiry: ""
      };
    })(),
    onSubmit: () => {
      values.account_mobile_number = values.account_mobile_number.replace(
        /\s/g,
        ""
      );
      onSubmit(values);
    },
    validate: (values: DirectDebitFormValues) => {
      const errors: FormikErrors<DirectDebitFormValues> = {};
      const sanitizedCardExpiry = values.card_expiry.replace(/\//g, "");

      if (
        values.card_expiry &&
        !values.card_expiry.match(/^(0[1-9]|1[0-2])\/([0-9]{2})$/)
      ) {
        errors.card_expiry = "Card expiry should be in MM/YY format";
      }

      if (
        sanitizedCardExpiry.length === 4 &&
        !validateCardExpirationDate(sanitizedCardExpiry)
      ) {
        errors.card_expiry = "Card has past expiry date";
      }

      return errors;
    },
    validationSchema: yup.object({
      account_mobile_number: yup
        .string()
        .required(
          t("{{field}} is required", { field: t("Mobile Number"), ns: "forms" })
        ),
      account_email: yup
        .string()
        .email("Email address is not valid")
        .required(
          t("{{field}} is required", { field: t("Email Address"), ns: "forms" })
        ),
      card_last_four: yup
        .string()
        .length(4, "Incomplete last four digits card number")
        .required(
          t("{{field}} is required", { field: t("Card Number"), ns: "forms" })
        ),
      card_expiry: yup
        .string()
        .required(
          t("{{field}} is required", { field: t("Valid Thru"), ns: "forms" })
        )
    })
  });

  return {
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
    values,
    touched,
    isValid,
    dirty
  };
};
