/**
 * Named it legacy because eventually we will use UPAPI for all payment channels
 * This context is used for payment method type Virtual Account and Over the Counter
 * And calling legacy API to create payment code
 */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef
} from "react";
import startCase from "lodash/startCase";
import { AxiosError } from "axios";
import {
  BankTransferChannelsEnum,
  PaymentChannelsEnum,
  PaymentMethodsEnum
} from "@xendit/checkout-utilities";

import { usePaymentLink } from "../PaymentLinkContext";
import { usePaymentMethod } from "../PaymentMethodContext";

import Dialog from "../../components/Dialog";

import { createLegacyAsyncPaymentCode } from "../../utils/fetch-resource";
import {
  AlternativeDisplayItem,
  AsyncChannelObject,
  InvoiceCurrency
} from "../../types/checkout";
import { logFetchUnexpectedResponse } from "../../utils/rum";

export interface InvoiceVirtualAccount {
  bank_code: string;
  collection_type: string;
  bank_account_number?: string;
  account_holder_name: string;
  alternative_displays?: AlternativeDisplayItem[];
}

export interface InvoiceRetailOutlet {
  retail_outlet_name: string;
  payment_code: string;
  transfer_amount: number;
  merchant_name: string;
}

const normalizeToAsyncChannelObject = (
  paymentMethodType:
    | PaymentMethodsEnum.BANK_TRANSFER
    | PaymentMethodsEnum.RETAIL_OUTLET,
  paymentChannel: PaymentChannelsEnum,
  data: InvoiceVirtualAccount | InvoiceRetailOutlet
) => {
  if (paymentMethodType === PaymentMethodsEnum.BANK_TRANSFER) {
    const virtualAccount = data as InvoiceVirtualAccount;

    return {
      name:
        paymentChannel === PaymentChannelsEnum.OTHER_BANKS
          ? PaymentChannelsEnum.OTHER_BANKS
          : virtualAccount.bank_code,
      merchant_name: virtualAccount.account_holder_name,
      payment_destination: virtualAccount.bank_account_number,
      alternative_displays: virtualAccount.alternative_displays
    } as AsyncChannelObject;
  }

  if (paymentMethodType === PaymentMethodsEnum.RETAIL_OUTLET) {
    const retailOutlet = data as InvoiceRetailOutlet;

    return {
      name: retailOutlet.retail_outlet_name,
      merchant_name: retailOutlet.merchant_name,
      payment_destination: retailOutlet.payment_code
    } as AsyncChannelObject;
  }
};

const getOtherBanksChannel = (
  isBniAvailable: boolean,
  isPermataAvailable: boolean
) => {
  if (isPermataAvailable) return PaymentChannelsEnum.PERMATA;
  if (isBniAvailable) return PaymentChannelsEnum.BNI;
};

const getVNOtherBanksChannel = (
  isWooriAvailable: boolean,
  isPVAvailable: boolean
) => {
  if (isWooriAvailable) return PaymentChannelsEnum.WOORI;
  if (!isWooriAvailable && isPVAvailable) return PaymentChannelsEnum.PV;
};

export type SetErrorPayload = {
  error: ErrorMessage;
};

export type LegacyAsyncPaymentContextValues = {
  error: ErrorMessage | null;
  isCreating: boolean;
  showError: boolean;
  isBniAvailable: boolean;
  isPermataAvailable: boolean;
  otherBanksChannel: BankTransferChannelsEnum;
  paymentInstrument: AsyncChannelObject;
  onUpdateFixedVaPaymentDestination: (
    bindingFixedVaChannel: string | null
  ) => void;
};

export type LegacyAsyncPaymentReducerState = {
  error: ErrorMessage | null;
  channelCode: string | null;
  created: boolean;
  isCreating: boolean;
  showError: boolean;
};

export type LegacyAsyncPaymentReducerAction =
  | { type: "dismiss_error" }
  | { type: "set_error"; payload: SetErrorPayload }
  | { type: "set_create_legacy_payment_code"; payload: { channelCode: string } }
  | { type: "async_channel_object_created" };

const initialState = {
  error: null,
  channelCode: null,
  created: false,
  isCreating: false,
  showError: false
};

const LegacyAsyncPaymentContext =
  createContext<LegacyAsyncPaymentContextValues>({
    ...initialState,
    isBniAvailable: false,
    isPermataAvailable: false,
    otherBanksChannel: PaymentChannelsEnum.BNI,
    paymentInstrument: { name: "", merchant_name: "" },
    onUpdateFixedVaPaymentDestination: () => {}
  });

const LegacyAsyncPaymentReducer = (
  state: LegacyAsyncPaymentReducerState,
  action: LegacyAsyncPaymentReducerAction
): LegacyAsyncPaymentReducerState => {
  switch (action.type) {
    case "dismiss_error":
      return { ...state, showError: false };
    case "set_error":
      return {
        ...state,
        error: action.payload.error,
        channelCode: null,
        created: false,
        isCreating: false,
        showError: true
      };
    case "set_create_legacy_payment_code":
      return {
        ...state,
        error: null,
        channelCode: null,
        isCreating: true,
        created: false,
        showError: false
      };
    case "async_channel_object_created":
      return {
        ...state,
        error: null,
        isCreating: false,
        showError: false,
        created: true
      };
    default:
      return state;
  }
};

export type LegacyAsyncPaymentProviderProps = {
  children: ReactNode;
};

const LegacyAsyncPaymentProvider: FC<LegacyAsyncPaymentProviderProps> = ({
  children
}) => {
  const {
    paymentLink: {
      invoice: {
        id: invoiceId,
        banks,
        retail_outlets,
        currency,
        fixed_va: fixedVa,
        callback_virtual_account: cva
      }
    },
    onMutateAsyncChannelObject
  } = usePaymentLink();
  const { clearPaymentChannel, paymentChannel, paymentMethodType } =
    usePaymentMethod();

  const { account_number: cvaAccountNumber } = cva || {};

  const [state, dispatch] = useReducer(LegacyAsyncPaymentReducer, initialState);

  const paymentInstruments =
    paymentMethodType === PaymentMethodsEnum.BANK_TRANSFER
      ? banks || []
      : retail_outlets || [];

  const paymentInstrument = paymentInstruments.find(
    (instrument) => instrument.name === paymentChannel?.channel
  ) as AsyncChannelObject;

  const isBniAvailable = Boolean(
    paymentInstruments.find(
      (instrument) => instrument.name === PaymentChannelsEnum.BNI
    )
  );
  const isPermataAvailable = Boolean(
    paymentInstruments.find(
      (instrument) => instrument.name === PaymentChannelsEnum.PERMATA
    )
  );

  const isWooriAvailable = Boolean(
    paymentInstruments.find(
      (instrument) => instrument.name === PaymentChannelsEnum.WOORI
    )
  );
  const isPVAvailable = Boolean(
    paymentInstruments.find(
      (instrument) => instrument.name === PaymentChannelsEnum.PV
    )
  );

  let otherBanksChannel: BankTransferChannelsEnum;
  switch (currency) {
    case InvoiceCurrency.Vnd:
      otherBanksChannel = getVNOtherBanksChannel(
        isWooriAvailable,
        isPVAvailable
      ) as BankTransferChannelsEnum;
      break;
    default:
      otherBanksChannel = getOtherBanksChannel(
        isBniAvailable,
        isPermataAvailable
      ) as BankTransferChannelsEnum;
  }

  const abortControllerRef = useRef<AbortController>();

  const handleUpdateFixedVaPaymentDestination = useCallback(
    (bindingFixedVaChannel: string | null) => {
      if (paymentInstrument.name === bindingFixedVaChannel) {
        paymentInstrument.payment_destination = cvaAccountNumber;
      }
    },
    [cvaAccountNumber]
  );

  const handleCreatingLegacyPaymentCode = useCallback(async () => {
    if (!paymentChannel) {
      return;
    }

    const channelCode =
      paymentChannel.channel === PaymentChannelsEnum.OTHER_BANKS
        ? otherBanksChannel
        : paymentChannel.channel;
    const isFixedVa = fixedVa;

    dispatch({
      type: "set_create_legacy_payment_code",
      payload: {
        channelCode: channelCode
      }
    });

    abortControllerRef.current = new AbortController();

    try {
      const legacyAsyncChannel = await createLegacyAsyncPaymentCode<
        InvoiceVirtualAccount | InvoiceRetailOutlet
      >(
        invoiceId,
        paymentMethodType as
          | PaymentMethodsEnum.BANK_TRANSFER
          | PaymentMethodsEnum.RETAIL_OUTLET,
        channelCode as string,
        isFixedVa,
        { abortSignal: abortControllerRef.current.signal }
      );

      const asyncChannelObject = normalizeToAsyncChannelObject(
        paymentMethodType as
          | PaymentMethodsEnum.BANK_TRANSFER
          | PaymentMethodsEnum.RETAIL_OUTLET,
        paymentChannel.channel,
        legacyAsyncChannel
      ) as AsyncChannelObject;

      const keyToMutate =
        paymentMethodType === PaymentMethodsEnum.BANK_TRANSFER
          ? "banks"
          : "retail_outlets";
      onMutateAsyncChannelObject(keyToMutate, asyncChannelObject);

      dispatch({
        type: "async_channel_object_created"
      });
    } catch (error) {
      let errorCode = "Error";
      // let errorMessage = "There was an error while creating payment code";
      let errorMessage = error + "";

      if (error instanceof AxiosError) {
        const errorResponse = error.response?.data;
        errorCode = startCase(errorResponse.error_code.toLowerCase());
        errorMessage = errorResponse.message;
      } else {
        logFetchUnexpectedResponse(error);
      }

      dispatch({
        type: "set_error",
        payload: {
          error: {
            title: errorCode,
            body: errorMessage
          }
        }
      });
    }
  }, [dispatch, invoiceId, paymentMethodType, paymentChannel, fixedVa]);

  useEffect(() => {
    const shouldGenerateFixedVa = fixedVa
      ? paymentChannel && !state.created
      : paymentChannel &&
        !state.created &&
        !paymentInstrument.payment_destination;

    const shouldGenerateVa =
      shouldGenerateFixedVa ||
      (!fixedVa && paymentChannel && !paymentInstrument.payment_destination);
    if (shouldGenerateVa) {
      handleCreatingLegacyPaymentCode();

      return () => {
        abortControllerRef.current?.abort();
      };
    }
  }, [
    fixedVa,
    paymentChannel,
    paymentInstrument.payment_destination,
    handleCreatingLegacyPaymentCode,
    state.created
  ]);

  return (
    <LegacyAsyncPaymentContext.Provider
      value={{
        ...state,
        isBniAvailable,
        isPermataAvailable,
        otherBanksChannel,
        paymentInstrument,
        onUpdateFixedVaPaymentDestination: handleUpdateFixedVaPaymentDestination
      }}
    >
      {children}
      <Dialog
        open={!!state.showError}
        title={state.error?.title}
        description={state.error?.body}
        buttons={[
          {
            text: "OK, Got it!",
            variant: "brand-secondary",
            onClick: () => {
              dispatch({ type: "dismiss_error" });
              clearPaymentChannel();
            }
          }
        ]}
      />
    </LegacyAsyncPaymentContext.Provider>
  );
};

export default LegacyAsyncPaymentProvider;

export const useLegacyAsyncPayment = () =>
  useContext(LegacyAsyncPaymentContext);
